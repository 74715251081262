import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/OAuthPage.css';  // Import styles
import config from '../config';

import { useTheme } from '../ThemeContext';
import { useFetchWrapper } from '../_helpers/fetchWrapper';

const { apiBaseUrl } = config;

function OAuthPage() {
    const navigate = useNavigate();
    const { setTheme } = useTheme();
    const fetchWrapper = useFetchWrapper();

  useEffect(() => {
    const fetchCallback = async () => {
      // Extract query parameters from the current URL
      const queryParams = new URLSearchParams(window.location.search);

      // Retrieve the verifier from sessionStorage
      const verifier = sessionStorage.getItem('verifier');
      sessionStorage.removeItem('verifier');

      const provider = sessionStorage.getItem('oauth-provider');
      sessionStorage.removeItem('oauth-provider');

      if (!verifier) {
        console.error('Verifier not found in sessionStorage');
        navigate('/login');
        return;
      }

      if (!provider) {
        console.error('Provider not found in sessionStorage');
        navigate('/login');
        return;
      }

      // Add the verifier to the query parameters
      queryParams.append('verifier', verifier);

      try {
        fetchWrapper.get(`${apiBaseUrl}/auth/${provider}/callback?${queryParams.toString()}`)
        .then((data) => {
          // Save the JWT token in sessionStorage
          sessionStorage.setItem('jwt', data.token);

          //set the userId from the jwt token
          const jwt = data.token
          const base64Url = jwt.split('.')[1];
          const base64 = base64Url.replace('-', '+').replace('_', '/');
          const payload = JSON.parse(window.atob(base64));
          const userId = payload.user_id;
          sessionStorage.setItem('userId', userId);

          fetchWrapper.get(`${apiBaseUrl}/user/profile/${userId}`)
          .then((data) => {
            // Save the user's profile image URL in sessionStorage
            const profileImageURL = data.profile_image_url || null;
            sessionStorage.setItem('ProfileImageURL', profileImageURL);

            //gets the theme from the data object and triggers a theme change
            sessionStorage.setItem('theme', data.theme);
            const theme = data.theme || config.defaultTheme;
            setTheme(theme);

            // Redirect to the users default page (home if unset)
            if (data.default_login_page !== undefined && data.default_login_page !== null && data.default_login_page !== '') {
              navigate(data.default_login_page);
            } else {
              navigate('/home');
            }
          })
        })

      } catch (error) {
        console.error('Failed to complete OAuth callback:', error);
        navigate('/login');
      }
    };

    fetchCallback();
  }, [navigate, setTheme, fetchWrapper]);

  return (
    <div className="oauth-page">
      <div className="oauth-page-content">
        <h1>Authenticating...</h1>
        <p>Please wait while we authenticate your account.</p>
      </div>
    </div>
  );
}

export default OAuthPage;