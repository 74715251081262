import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import config from '../../config';
import '../../styles/PerformerHome.css';
import '../../styles/partial/PerformerSongList.css'; // Add this line to include the new CSS

import { useFetchWrapper } from '../../_helpers/fetchWrapper';

const PerformerSongList = () => {
  const { id } = useParams();
  const [songs, setSongs] = useState([]);
  const fetchWrapperRef = useRef(useFetchWrapper());

  const fetchSongsData = useCallback(async () => {
    try {
      const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/bands/${id}/songs`);
      setSongs(data);
    } catch (error) {
      console.error('Error fetching songs data:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchSongsData();
  }, [fetchSongsData]);

  return (
    <div className="performer-songlist-content">
      <h1>Song List</h1>
      <ul>
        {songs.map(song => (
          <li key={song.ID}>
            <h2>{song.Title}</h2>
            <p><strong>Artist:</strong> {song.Artist}</p>
            <p><strong>Lyrics:</strong> {song.Lyrics}</p>
            <p><strong>Cords:</strong> {song.Cords}</p>
            <p><strong>Tempo:</strong> {song.Tempo}</p>
            <p><strong>Key:</strong> {song.Key}</p>
            <p><strong>Release Date:</strong> {new Date(song.ReleaseDate).toLocaleDateString()}</p>
            <p><strong>Original Album:</strong> {song.OriginalAlbum}</p>
            <p><strong>Song Writer:</strong> {song.SongWriter}</p>
            <p><strong>Genres:</strong> {song.Genres.map(genre => genre.Name).join(', ')}</p>
            <p><strong>Tags:</strong> {song.Tags.map(tag => tag.Name).join(', ')}</p>
            <p><strong>Last Played:</strong> {new Date(song.LastPlayed).toLocaleDateString()}</p>
            <p><strong>Average Rating:</strong> {song.AverageRating}</p>
            <p><strong>Active:</strong> {song.Active}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PerformerSongList;