import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IntroPage from './components/IntroPage';
import LoginPage from './components/LoginPage';
import OAuthPage from './components/OAuthPage';
import HomePage from './components/HomePage';
import ProfilePage from './components/ProfilePage';
import PerformersSearchPage from './components/PerformersSearchPage';
import RegisterBand from './components/RegisterBand';
import Footer from './components/Footer';
import PerformerHome from './components/PerformerHome';

import './App.css';  // Import styles
import { ThemeProvider, useTheme } from './ThemeContext';  // Import the ThemeProvider
import './styles/themes.css';

import BandLayoutPage from './components/partial/layout/BandLayoutPage';

import AddSong from './components/partial/AddSong';
import PerformerSongList from './components/partial/PerformerSongList';

import PerformerNavSongList from './components/partial/navigation/PerformerNavSongList';

function App() {
  const [showLoginPage, setShowLoginPage] = useState(false);
  const { applyStoredTheme } = useTheme();

  useEffect(() => {
    applyStoredTheme();
  }, [applyStoredTheme]);

  const handleLoginClick = () => {
    setShowLoginPage(true);
  };

  return (
      <Router>
        <div className="App">
          <div className="App-content">
            <Routes>
              <Route path="/" element={showLoginPage ? <LoginPage /> : <IntroPage onLoginClick={handleLoginClick} />} />
              <Route path="/oauth" element={<OAuthPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register-band" element={<RegisterBand />} />
              <Route path="/performers" element={<PerformersSearchPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/error" element={<div>Error: Authentication token not found</div>} />

              <Route path="/performers/:id" element={<PerformerHome />} />
              <Route path="/performers/:id/song-list" element={
                <BandLayoutPage
                  navigation={ <PerformerNavSongList /> }
                >
                  <PerformerSongList />
                </BandLayoutPage>} />

              <Route path="/performers/:id/add-song" element={
                <BandLayoutPage 
                  previousPage="/performers/:id/song-list"
                >
                  <AddSong />
                </BandLayoutPage>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
  );
}

const AppWrapper = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

export default AppWrapper;
