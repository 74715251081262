import React, { createContext, useState, useContext, useEffect } from 'react';
import config from './config';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(config.defaultTheme);

  useEffect(() => {
    const root = document.documentElement;
      root.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    const storedTheme = sessionStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  const applyStoredTheme = () => {
    const storedTheme = sessionStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme, applyStoredTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);