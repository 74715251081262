import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import '../styles/ProfilePage.css';  // Import styles
import config from '../config';  // Import the default export
import { useTheme } from '../ThemeContext';

import { useFetchWrapper } from '../_helpers/fetchWrapper';

function ProfilePage() {
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const navigate = useNavigate();

  const { theme, setTheme } = useTheme();
  const [themes, setThemes] = useState([]);

  const [homePage, setHomePage] = useState('');
  const [homePages, setHomePages] = useState([]);

  const fetchWrapperRef = useRef(useFetchWrapper());

  useEffect(() => {
    const fetchProfileData = async () => {

      const userId = sessionStorage.getItem('userId');
      const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/user/profile/${userId}`)

      setUsername(data.username || '');
      setBio(data.bio || '');
      setTheme(data.theme || config.defaultTheme);
      setHomePage(data.default_login_page || '');

    };

    fetchProfileData();
  }, [navigate, setTheme]);

  useEffect(() => {
    const fetchThemes = async () => {
    const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/themes`)
    setThemes(data);
    };

    fetchThemes();
  }, [navigate, setThemes]);

  useEffect(() => {
    const fetchHomePages = async () => {
      const data = await fetchWrapperRef.current.get(`${config.apiBaseUrl}/user/homepageoptions`)
      setHomePages(data);
    };

    fetchHomePages();
  }, [navigate, setHomePages]);

  const handleSave = async () => {
    await fetchWrapperRef.current.put(`${config.apiBaseUrl}/user`,
      { username, bio, theme, default_login_page: homePage });

    navigate('/home');
  };

  const handleThemeChange = (e) => {
    sessionStorage.setItem('theme', e.target.value);
    setTheme(e.target.value);
  };

  const handleHomePageChange = (e) => {
    setHomePage(e.target.value);
  }

  return (
    <div className="profile-page">
      <Logo />
      <div className="profile-page-wrapper">
        <div className="profile-page-featured-area">
          <div className="profile-page-content">
            <h2>Update Profile</h2>
            <div className="profile-form">
              <label>
                Public Username:
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="profile-input"
                  maxLength={50}
                />
              </label>
              <label>
                Public Bio:
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="profile-textarea"
                />
              </label>
              <label>
                Theme:
                <select
                    className="profile-page-form-buttons"
                    value={theme}
                    onChange={handleThemeChange}
                  >
                    {themes.map((theme) => (
                      <option key={theme.id} value={theme.css_name}>
                        {theme.friendly_name}
                      </option>
                    ))}
                </select>
              </label>
              <label>
                Home Page:
                <select
                    className="profile-page-form-buttons"
                    value={homePage}
                    onChange={handleHomePageChange}
                  >
                    {homePages.map((page) => (
                      <option key={page.id} value={page.default_login_page}>
                        {page.friendly_name}
                      </option>
                    ))}
                </select>
              </label>
              <div className="profile-page-form-nav">
                  <button className="profile-page-form-buttons" onClick={() => navigate('/home')}>Cancel</button>
                  <button className="profile-page-form-buttons" onClick={handleSave}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;