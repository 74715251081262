import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import config from '../../config';
import '../../styles/partial/AddSong.css';

import { useFetchWrapper } from '../../_helpers/fetchWrapper';

const AddSong = () => {
  const [song, setSong] = useState({
    Title: '',
    Artist: '',
    Lyrics: '',
    Cords: '',
    Tempo: '',
    Key: '',
    ReleaseDate: '',
    OriginalAlbum: '',
    SongWriter: '',
    Active: false,
    Genres: '',
    Tags: '',
  });

  const navigate = useNavigate();
  const fetchWrapper = useFetchWrapper();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSong((prevSong) => ({
      ...prevSong,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetchWrapper.post(`${config.apiBaseUrl}/songs`, song);
      navigate('/performer-songlist');
    } catch (error) {
      console.error('Error adding song:', error);
    }
  };

  return (
    <div className="add-song">
        <h1>Add New Song</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title</label>
            <input type="text" name="Title" value={song.Title} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Artist</label>
            <input type="text" name="Artist" value={song.Artist} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Lyrics</label>
            <textarea name="Lyrics" value={song.Lyrics} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Cords</label>
            <textarea name="Cords" value={song.Cords} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Tempo</label>
            <input type="text" name="Tempo" value={song.Tempo} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Key</label>
            <input type="text" name="Key" value={song.Key} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Release Date</label>
            <input type="date" name="ReleaseDate" value={song.ReleaseDate} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Original Album</label>
            <input type="text" name="OriginalAlbum" value={song.OriginalAlbum} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Song Writer</label>
            <input type="text" name="SongWriter" value={song.SongWriter} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Genres</label>
            <input type="text" name="Genres" value={song.Genres} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Tags</label>
            <input type="text" name="Tags" value={song.Tags} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Active</label>
            <input type="checkbox" name="Active" checked={song.Active} onChange={(e) => setSong((prevSong) => ({ ...prevSong, Active: e.target.checked }))} />
          </div>
          <button type="submit" className="btn-save"><FaSave /> Save</button>
        </form>
    </div>
  );
};

export default AddSong;