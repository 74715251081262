import React from 'react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import '../styles/Footer.css';  // Import styles
import TawkTo from './TawkTo.js';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <a href="/terms-of-service" className="footer-link">Terms of Service</a>
        <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
      </div>
      <div className="footer-right">
        <a href="https://discord.com" className="footer-link" aria-label="Discord">
          <FaDiscord className="footer-icon" />
        </a>
        <a href="https://twitter.com" className="footer-link" aria-label="Twitter">
          <FaTwitter className="footer-icon" />
        </a>
      </div>
      {process.env.NODE_ENV !== 'development' && (
        <TawkTo />
      )}
    </footer>
  );
}

export default Footer;