import { useParams, Link } from 'react-router-dom';

import { FaSearch, FaPlus, FaFileImport, FaEdit, FaTrash, FaCog, FaRobot } from 'react-icons/fa';

import '../../../styles/partial/navigation/PerformerNavSongList.css';


const PerformerNavSongList = () => {

    const { id } = useParams();

    return (
        <div className="performer-page-search-container">
            <input type="text" placeholder="Search Song" className="performer-page-search-input" />
            <FaSearch className="performer-page-search-icon" />
            <Link to={`/performers/${id}/add-song`} className="performer-page-search-button"><FaPlus className="icon" /> Add Song</Link>
            <a href="#import-songlist"><FaFileImport className="icon" /> Import Songlist</a>
            <a href="#edit-songs"><FaEdit className="icon" /> Edit Songs</a>
            <a href="#delete-songs"><FaTrash className="icon" /> Delete Songs</a>
            <a href="#settings"><FaCog className="icon" /> Settings</a>
            <a href="#ai-options"><FaRobot className="icon" /> AI Options</a>
        </div>
    );
};

export default PerformerNavSongList;