import { useEffect } from 'react';

import '../../styles/partial/LoadingSpinner.css';

const LoadingSpinner = () => {
  useEffect(() => {
  });

    return (
        <div className="loading-animation">
            <div className="spinner">+</div>
        </div>
    )
}
export default LoadingSpinner;